import(/* webpackMode: "eager" */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager" */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Tinos-Regular.woff\"}],\"variableName\":\"tinos\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/src/app/common/react-query-provider/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/src/app/context/AuthContext.js");
;
import(/* webpackMode: "eager" */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/src/app/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/src/app/styles/index.css");
;
import(/* webpackMode: "eager" */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/src/app/styles/style.css");
;
import(/* webpackMode: "eager" */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/src/app/styles/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/Users/hosumlai/Documents/projects/ohmyink/ohmyink-next/src/theme.js");
